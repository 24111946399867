@import "../../styles/variable";
.tag {
  background-color: rgba($dark, 0%);
  border: 1px solid rgba($white, 14%);
  padding: 4px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #dddddd;
  font-family: "geist-regular";
  font-weight: 400;
  width: fit-content;
  @media (max-width: 992px) {
    font-size: 14px;
  }
  &.stroke {
    margin-inline: auto;
    background-color: rgba(#f8f9fc, 10%);
    border: 1px solid rgba(#d5d9eb, 10%);
  }
  span {
    display: flex;
    width: 8px;
    height: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
