@import "../../../../styles/variable";

.header {
  position: relative;
  .bgImg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  a {
    z-index: 1;
    position: relative;
  }
  h3 {
    color: #f5f5f6;
    font-size: 30px;
    line-height: 38px;
    font-family: "geist-semibold";
    font-weight: 600;
    position: relative;
    z-index: 1;
    margin-block: 24px 12px;
  }
  p {
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    color: #94969c;
    font-size: 16px;
    line-height: 24px;
    font-family: "geist-regular";
    font-weight: 400;
  }
}

.selectPhone {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  background: transparent;
  padding: 0 14px;
  height: 44px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  transition: border 0.5s ease;
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  &::placeholder {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: #667085;
    text-overflow: ellipsis;
    font-family: "geist-regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    border: 1px solid $primary;
  }

  :global(.PhoneInput) {
    height: 100%;
    input {
      border: none;
      min-width: 40px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-repeat: no-repeat;
      background-position: center right;
      color: rgba($white, 0.8);
      font-size: 16px;
      line-height: 24px;
      font-family: "geist-regular";
      font-weight: 400;
      background-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      input {
        border: none;
        box-shadow: none;

        &:focus {
          outline: none;
        }
      }
    }
  }
  :global(.PhoneInputCountrySelectArrow) {
    border-color: $white;
    opacity: 1;
    width: 8px;
    height: 8px;
    margin-right: 10px;
  }
}

.formBtns {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.error {
  color: #f97066;
  font-size: 14px;
  width: 100%;
  font-family: "geist-medium";
  text-align: left;
  margin-top: 5px;
  margin-left: -15px;
}

.phoneError {
  margin-bottom: 30px;
}

.phoneLabel {
  display: block;
  margin-bottom: 5px;
  color: #cecfd2;
  font-size: 14px;
  line-height: 20px;
  font-family: "geist-medium";
  font-weight: 500;
  text-align: left;
}
