@import "../../../../styles/variable";

.works-section {
  max-width: 1200px;
  margin-inline: auto;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    @media (max-width: 600px) {
      gap: 15px;
      flex-direction: column;
    }
    .left {
      h2 {
        margin-top: 24px;
        font-size: 52px;
        line-height: 56px;
        font-family: "geist-medium";
        font-weight: 500;
        letter-spacing: -0.84px;
        color: $white;
        @media (max-width: 768px) {
          margin-top: 12px;
          font-size: 40px;
          line-height: 44px;
        }
        @media (max-width: 600px) {
          font-size: 35px;
          line-height: 40px;
        }
      }
      @media (max-width: 600px) {
        text-align: center;
        .tag {
          margin-inline: auto !important;
        }
      }
    }
  }
  .videos-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 64px;
    margin-block: 64px;
    min-height: 360px;
    @media (max-width: 1200px) {
      gap: 20px;
    }
    @media (max-width: 768px) {
      margin-block: 32px;
      grid-template-columns: 1fr;
      min-height: 600px;
    }
    video,
    iframe {
      border-radius: 24px;
      width: 100%;
      height: 100%;
      @media (max-width: 768px) {
        border-radius: 12px;
        width: 75%;
        margin-inline: auto;
        @media (max-width: 600px) {
          width: 95%;
        }
      }
    }
  }
  p {
    color: $white;
    font-size: 18px;
    line-height: 28px;
    font-family: "geist-regular";
    font-weight: 400;
    @media (max-width: 600px) {
      text-align: center;
      font-size: 16px;
      line-height: 26px;
    }
  }
}
