@import "../../styles/variable";

.blog-detail {
  margin-top: 50px;
  text-align: center;
  max-width: 992px;
  margin-inline: auto;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  p,
  li,
  a,
  i {
    color: rgba($white, 70%);
    font-family: "geist-regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    // margin-bottom: 40px;
  }
  .blog-title {
    max-width: 768px;
    margin-inline: auto;
    font-size: 32px;
    line-height: 42px;
    font-family: "geist-medium";
    font-weight: 500;
    color: rgba($white, 90%);
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .content {
    text-align: left;
    margin-top: 50px;
    @media (max-width: 768px) {
      margin-top: 10px;
    }
    p {
      color: rgba($white, 70%);
      font-family: "geist-regular";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;
      }
    }
    .avatar {
      margin-right: 12px;
      img {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        object-fit: cover;
      }
    }
    .d-flex {
      display: flex;
      padding-block: 50px;
      .text {
        h4 {
          color: rgba($white, 90%);
          margin: 0px;
          font-family: "geist-semiBold";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
        p {
          color: rgba($white, 70%);
          font-family: "geist-regular";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin: 0px;
          line-height: 20px;
        }
      }
    }
  }
}
