@import "../../styles/variable";

.cookies-hero {
  max-width: 768px;
  margin-inline: auto;
  text-align: center;
  color: $white;
  padding-block: 96px;
  margin-top: 66px;

  .content {
    span {
      color: #cecfd2;
      font-size: 16px;
      line-height: 24px;
      font-family: "geist-semibold";
      font-weight: 600;
    }
    p {
      color: #94969c;
      font-size: 20px;
      line-height: 30px;
      font-family: "geist-regular";
      font-weight: 400;
    }
    h2 {
      padding-block: 12px 24px;
      font-size: 47px;
      line-height: 54px;
      font-family: "geist-medium";
      font-weight: 500;
      background: radial-gradient(circle, rgba($white, 1), rgba($white, 50%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .desc {
    margin-top: 75px;
    text-align: left;
    h4 {
      margin-block: 15px 10px;
      font-size: 20px;
      font-family: "geist-medium";
      font-weight: 500;
      color: $white;
    }
    p {
      color: #94969c;
      font-size: 16px;
      line-height: 28px;
      font-family: "geist-regular";
      font-weight: 400;
      a {
        color: $primary;
      }
    }
  }
}
