@import "../../../styles/variable";
.two-factor-form {
  max-width: 480px;
  .otpClass {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    .header {
      width: 100%;
    }
    form {
      @media (max-width: 768px) {
        max-width: 360px;
        margin-inline: auto;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
      @media (max-width: 450px) {
        max-width: 290px;
      }
      h3 {
        font-size: 30px;
        line-height: 38px;
        font-family: "geist-semibold";
        font-weight: 600;
        color: $white;
        @media (max-width: 450px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      p {
        font-size: 16px;
        line-height: 24px;
        font-family: "geist-regular";
        font-weight: 400;
        color: $text;
        margin-block: 8px 16px;
        @media (max-width: 450px) {
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
    h6 {
      font-size: 14px;
      line-height: 20px;
      font-family: "geist-regular";
      font-weight: 400;
      color: #667085;
    }
    .verification-code {
      color: $primary;
      font-size: 14px;
      font-family: "geist-medium";
      font-weight: 500;
      margin-bottom: 5px;
      @media (max-width: 450px) {
        margin-top: 15px;
      }
    }
    .otp-code {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: space-between;
      input {
        width: calc(100% / 6);
        aspect-ratio: 2 / 2;
        border: 2px solid #d0d5dd;
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        font-size: 24px;
        color: $white;
        font-family: "geist-medium";
        font-weight: 500;
        box-shadow: 0px 0px 12px rgba(#d0d5dd, 10%);
        transition: 0.3s linear;
        @media (max-width: 450px) {
          font-size: 20px;
        }
        &.error {
          border: 2px solid #f97066;
          &:hover {
            border: 2px solid #f97066;
          }
        }
        &:hover {
          border: 2px solid $primary;
        }
        &:focus {
          border: 2px solid $primary;
        }
      }
    }
    .invalid {
      color: #f97066;
    }
    button {
      margin-top: 30px;
      width: 100%;
    }
  }
}
