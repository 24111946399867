@import "../../../styles/variable";

.job-card {
  border: 1px solid #1f242f;
  background-color: rgba(#0c111d, 50%);
  border-radius: 16px;
  padding: 24px;
  position: relative;
  max-width: 768px;
  width: 100%;
  margin-inline: auto;
  margin-top: 24px;
  transition: 0.3s linear;
  &:hover {
    box-shadow: 0 6px 6px 12px rgba($primary, 8%);
    transform: translateY(-4px);
  }
  .url {
    position: absolute;
    top: 24px;
    right: 24px;
    p {
      cursor: pointer;
      color: #cecfd2;
      font-family: "geist-semibold";
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .job-cat {
    font-family: "geist-medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #cecfd2;
  }
  h4 {
    margin-top: 6px;
    font-family: "geist-semibold";
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #f5f5f6;
    margin-bottom: 16px;
    .short-cat {
      color: #84caff;
      background-color: #102a56;
      border: 1px solid #1849a9;
      border-radius: 99px;
      padding: 3px 6px 3px 18px;
      position: relative;
      font-size: 14px;
      line-height: 20px;
      font-family: "geist-medium";
      font-weight: 500;
      margin-left: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        left: 10px;
        background-color: #2e90fa;
      }
    }
  }
  p {
    font-family: "geist-regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #94969c;
    margin-bottom: 16px;
  }
  .job-type-time {
    display: flex;
    align-items: center;
    gap: 18px;
    .content {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        font-family: "geist-medium";
        font-size: 16px;
        line-height: 24px;
        color: #94969c;
        font-weight: 500;
      }
    }
  }
}
