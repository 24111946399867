@import "../../../styles/variable";

.signupContent {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  @media (max-width: 992px) {
    gap: 0px;
  }
  .leftChild {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 20px;
    padding-inline: 55px;
    background-color: #010314;
    border-right: 1px solid rgba($white, 10%);
    min-width: 440px;
    @media (max-width: 992px) {
      min-width: 340px;
      padding-inline: 35px;
    }
    @media (max-width: 768px) {
      display: none;
    }
    .customStepper {
      display: flex;
      flex-direction: column;
      .step {
        position: relative;
        display: flex;
        gap: 16px;
        padding-bottom: 32px;
        min-height: 80px;
        &:last-child {
          padding-bottom: 0px;
          &::after {
            content: none;
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 35px;
          left: 16px;
          transform: translateX(-50%);
          width: 2px;
          height: calc(100% - 38px);
          background-color: #1f242f;
          border-radius: 2px;
        }
        &.active {
          .circle {
            border: 2px solid #4a5bf3;
            &::before {
              content: "";
              background-color: #4a5bf3;
            }
            &::after {
              content: "";
              position: absolute;
              top: 107%;
              left: 50%;
              transform: translateX(-50%);
              width: 2px;
              height: 44px;
              background-color: #1f242f;
              border-radius: 2px;
            }
          }
        }
        &.completed {
          .circle {
            background-color: #4a5bf3;
            border: 2px solid #4a5bf3;
            &::before {
              content: url("../../../assets/images/tick.svg");
              background-color: transparent;
              width: 16px;
              height: 14px;
            }
          }
          &::after {
            background-color: #4a5bf3;
          }
        }
        .circle {
          position: relative;
          background-color: #161b26;
          border: 2px solid #1f242f;
          min-width: 32px;
          max-width: 32px;
          height: 32px;
          border-radius: 50%;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #61646c;
          }
        }
        .info {
          max-width: 248px;
          width: 100%;
          h5 {
            font-family: "geist-semibold";
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #cecfd2;
          }
          p {
            font-family: "geist-regular";
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #94969c;
          }
        }
      }
    }
    .header {
      margin-bottom: 80px;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #94969c;
        font-family: "geist-regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .rightChild {
    padding-inline: 20px;
    max-width: 490px;
    width: 100%;
    margin-inline: auto;
    text-align: center;
    padding-block: 100px;
    @media (max-width: 768px) {
      padding-block: 50px;
    }
    .footer {
      @media (min-width: 768px) {
        display: none;
      }
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #94969c;
        font-family: "geist-regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}
