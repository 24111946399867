@import "../../styles/variable";

.input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
  position: relative;
  &.errorField {
    input {
      border: 1px solid #f97066;
      &:hover {
        border: 1px solid #f97066;
      }
    }
  }
  &.full {
    width: 100%;
  }
  label {
    color: #cecfd2;
    font-size: 14px;
    line-height: 20px;
    font-family: "geist-medium";
    font-weight: 500;
    text-align: left;
  }
  input {
    background-color: #0c111d;
    border: 1px solid #333741;
    border-radius: 8px;
    height: 44px;
    padding-inline: 15px;
    color: rgba($white, 0.8);
    font-size: 16px;
    line-height: 24px;
    font-family: "geist-regular";
    font-weight: 400;
    transition: 0.3s linear;
    &:hover {
      border: 1px solid $primary;
    }
    &.errorInput {
      border-color: #f97066;
      &:hover {
        border-color: #f97066;
      }
    }
    &::placeholder {
      font-size: 14px;
    }
  }
  .error {
    color: #f97066;
    font-size: 14px;
    width: 100%;
    font-family: "geist-medium";
    text-align: left;
  }
  .passEye {
    position: absolute;
    right: 15px;
    width: 17px;
    height: 17px;
    top: 38px;
    cursor: pointer;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    font-family: "Regular";
  }
}
