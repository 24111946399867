@import "../../../../styles/variable";

.solution-section {
  max-width: 1200px;
  margin-inline: auto;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }
    .text-content {
      max-width: 652px;
      width: 100%;
      h2 {
        margin-top: 12px;
        font-size: 47px;
        font-weight: 500;
        font-family: "geist-medium";
        line-height: 54px;
        letter-spacing: -0.48px;
        background: radial-gradient(circle, rgba($white, 1), rgba($white, 50%));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media (max-width: 992px) {
          font-size: 40px;
          line-height: 47px;
        }
        @media (max-width: 768px) {
          font-size: 32px;
          line-height: 35px;
        }
      }
      p {
        max-width: 577px;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        font-family: "geist-regular";
        line-height: 30px;
        color: #dfe1f4;
        margin-block: 20px;
        @media (max-width: 992px) {
          margin-block: 14px;
        }
      }
    }
    .vid-content {
      max-width: 393px;
      width: 100%;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .solution-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 124px;
    padding-top: 96px;
    @media (max-width: 992px) {
      gap: 40px;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .col {
      max-width: 304px;
      width: 100%;
      transition: 0.3s linear;
      @media (max-width: 768px) {
        max-width: 100%;
        text-align: center;
      }
      &:hover {
        transform: translateY(-10px);
      }
      .icon {
        width: 60px;
        height: 60px;
        @media (max-width: 768px) {
          margin-inline: auto;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      h4 {
        margin-top: 30px;
        font-size: 15px;
        font-family: "geist-medium";
        font-weight: 500;
        color: $white;
      }
      p {
        margin-block: 11px 17px;
        font-size: 13px;
        font-family: "geist-regular";
        font-weight: 400;
        line-height: 23px;
        color: #dfe1f4;
        max-width: 240px;
        width: 100%;
        @media (max-width: 768px) {
          max-width: 95%;
        }
      }
      a {
        text-decoration: none;
        font-size: 13px;
        font-family: "geist-medium";
        font-weight: 500;
        line-height: 21px;
        color: $white;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          justify-content: center;
        }
        span {
          transition: 0.3s linear;
          margin-left: 5px;
        }
        &:hover {
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
