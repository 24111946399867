@import "../../styles/variable";

.auth-login {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-height: 100vh;
  grid-gap: 32px;
  @media (max-width: 1300px) {
    grid-gap: 0px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .left {
    padding-top: 100px;
    display: flex;
    min-width: 640px;
    @media (max-width: 1300px) {
      min-width: 480px;
    }
    @media (max-width: 1060px) {
      min-width: 400px;
      min-height: 100vh;
      padding-block: 30px;
      padding-inline: 20px;
    }
    .content {
      max-width: 400px;
      width: 100%;
      margin-inline: auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media (max-width: 992px) {
        max-width: 95%;
      }
      .header {
        width: 100%;
        margin-bottom: 50px;
        a {
          svg {
            .white-bg {
              transition: 0.3s linear;
            }

            .hover {
              transition: 0.3s linear;
            }
          }
        }
        &:hover {
          a {
            svg {
              .white-bg {
                fill: $white;
              }
              .hover {
                fill: $primary;
              }
            }
          }
        }
      }
      form {
        width: 100%;
        h3 {
          font-size: 36px;
          line-height: 44px;
          font-family: "geist-semibold";
          font-weight: 600;
          color: #f5f5f6;
          letter-spacing: -2%;
        }
        .verification-code {
          margin-bottom: 0;
        }
        p {
          text-align: center;
          font-size: 16px;
          line-height: 24px;
          font-family: "geist-regular";
          margin-block: 8px 16px;
          margin-bottom: 32px;
          color: #94969c;
        }
        .wrapper-input {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          @media (max-width: 600px) {
            grid-template-columns: 1fr;
            gap: 0;
          }
        }
        .input {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-bottom: 10px;
          position: relative;
          &.full {
            width: 100%;
          }
          label {
            color: #cecfd2;
            font-size: 14px;
            line-height: 20px;
            font-family: "geist-medium";
            font-weight: 500;
            .req {
              color: #f97066;
            }
          }
          input,
          select {
            background-color: #0c111d;
            border: 1px solid #333741;
            border-radius: 8px;
            height: 44px;
            padding-inline: 15px;
            color: #85888e;
            font-size: 16px;
            line-height: 24px;
            font-family: "geist-regular";
            font-weight: 400;
            transition: 0.3s linear;
            &:hover,
            &:focus {
              border: 1px solid $primary;
            }
            &.error-input {
              border-color: #f97066;
              &:hover {
                border-color: #f97066;
              }
            }
            &::placeholder {
              font-size: 14px;
            }
          }
          select {
            max-width: 275px;
            width: 100%;
            padding-left: 10px;
            @media (max-width: 600px) {
              max-width: 100%;
            }
          }
          .error {
            color: #f97066;
            font-size: 14px;
            width: 100%;
            font-family: "geist-medium";
          }
          .pass-eye {
            position: absolute;
            right: 15px;
            width: 17px;
            height: 17px;
            top: 38px;
            cursor: pointer;
          }
          span {
            font-size: 14px;
            line-height: 20px;
            font-family: "Regular";
          }
        }
        .form-check {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 20px;
          div {
            display: flex;
            gap: 8px;

            label {
              color: #cecfd2;
              font-size: 14px;
              line-height: 20px;
              font-family: "geist-medium";
              font-weight: 500;
            }
            input[type="checkbox"] {
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              width: 20px;
              height: 20px;
              border: 2px solid #333741;
              border-radius: 6px;
              outline: none;
              cursor: pointer;
            }

            /* Background color for unchecked state */
            input[type="checkbox"]:not(:checked) {
              background-color: transparent;
            }

            /* Background color for checked state */
            input[type="checkbox"]:checked {
              background-color: $primary;
              border-color: $primary;
            }

            /* Create a custom checkmark */
            input[type="checkbox"]:checked::after {
              content: "";
              display: block;
              width: 4px;
              height: 11px;
              border: solid $white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              margin: 0px 6px;
            }
          }
          a {
            font-size: 14px;
            line-height: 20px;
            font-family: "geist-semibold";
            font-weight: 600;
            color: #cecfd2;
            text-decoration: none;
          }
        }

        .primary-button {
          width: 100%;
          justify-content: center;
          margin-top: 20px;
        }
        h5 {
          font-size: 14px;
          line-height: 20px;
          font-family: "geist-regular";
          font-weight: 400;
          color: #94969c;
          margin-top: 20px;
          text-align: center;
          a {
            font-family: "geist-semibold";
            color: #cecfd2;
            text-decoration: none;
          }
        }
        .left-align {
          text-align: left;
        }
      }
    }
  }
  .right {
    min-height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    video {
      max-width: 800px;
      object-fit: cover;
      object-position: bottom;
      @media (max-width: 1060px) {
        max-width: 100%;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
