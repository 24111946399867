@import "../../../../styles/variable";

.header {
  position: relative;
  .bgImg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  a {
    z-index: 1;
    position: relative;
  }
  h3 {
    color: #f5f5f6;
    font-size: 30px;
    line-height: 38px;
    font-family: "geist-semibold";
    font-weight: 600;
    position: relative;
    z-index: 1;
    margin-block: 24px 12px;
  }
  p {
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    color: #94969c;
    font-size: 16px;
    line-height: 24px;
    font-family: "geist-regular";
    font-weight: 400;
  }
}
.error {
  color: #f97066;
  font-size: 14px;
  width: 100%;
  font-family: "geist-medium";
  text-align: left;
  margin-top: 5px;
}

.confirmCheckbox {
  text-align: left;
  display: flex;
  // align-items: center;
  gap: 10px;
  label {
    color: #94969C;
    font-size: 14px;
    line-height: 20px;
    font-family: "geist-medium";
    font-weight: 500;
  }
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #333741;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
  }

  /* Background color for unchecked state */
  input[type="checkbox"]:not(:checked) {
    background-color: transparent;
  }

  /* Background color for checked state */
  input[type="checkbox"]:checked {
    background-color: $primary;
    border-color: $primary;
  }

  /* Create a custom checkmark */
  input[type="checkbox"]:checked::after {
    content: "";
    display: block;
    width: 4px;
    height: 11px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin: 0px 6px;
  }
}

.formBtns{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}