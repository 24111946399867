@import "../../../styles/variable";

.get-started-section {
  margin-block: 96px;
  border: 1px solid rgba($white, 19%);
  border-radius: 38px;
  min-height: 332px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin-inline: auto;
  text-align: center;
  background-image: url("../../../assets/images/hero/heroGradient.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
  padding-block: 64px;
  padding-inline: 20px;
  .content {
    max-width: 768px;
    width: 100%;
    margin-inline: auto;
    text-align: center;
    h2 {
      color: #f5f5f6;
      font-family: "geist-semibold";
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -2%;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        font-size: 28x;
        line-height: 36px;
        margin-bottom: 14px;
      }
    }
    p {
      max-width: 650px;
      width: 100%;
      margin-inline: auto;
      color: #94969c;
      font-family: "geist-regular";
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 32px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
       }
    }
    button {
      margin-inline: auto;
      margin-bottom: 10px;
    }
  }
}
