@import "../../../../styles/variable";

.sliderSection {
  padding: 0;
  padding-top: 60px;
  height: 140px;
  display: flex;
  align-items: flex-end;
  .featuredSlider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    h3 {
      position: relative;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 2%;
      font-family: "geist-regular";
      font-weight: 400;
      color: $white;
    }
    .slider {
      width: 100%;
      max-width: 1010px;
      max-height: 40px;
      .sliderImg {
        max-width: 76px !important;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

:global(.slick-slide) {
  display: flex !important;
  justify-content: center;
  @media (max-width: 580px) {
    justify-content: flex-start;
  }
}
