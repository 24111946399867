@import "../../../../styles/variable";

.header {
  position: relative;
  .bgImg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  a {
    z-index: 1;
    position: relative;
  }
  h3 {
    color: #f5f5f6;
    font-size: 30px;
    line-height: 38px;
    font-family: "geist-semibold";
    font-weight: 600;
    position: relative;
    z-index: 1;
    margin-block: 24px 12px;
  }
  p {
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    color: #94969c;
    font-size: 16px;
    line-height: 24px;
    font-family: "geist-regular";
    font-weight: 400;
  }
}

h5 {
  font-size: 14px;
  line-height: 20px;
  font-family: "geist-medium";
  font-weight: 500;
  position: relative;
  color: #cecfd2;
  text-align: left;
}
h6 {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-family: "geist-regular";
  font-weight: 400;
  color: #94969c;
  position: relative;
  margin-top: 10px;
  span {
    cursor: pointer;
    border-bottom: 1px solid #94969c;
  }
}
.verificationCode {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  span {
    color: #333741;
    font-size: 60px;
    line-height: 72px;
    font-family: "geist-medium";
    font-weight: 500;
    &.errorInput {
      color: #f97066;
    }
  }
  .otpCode {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    margin-block: 5px;
    @media (max-width: 768px) {
      gap: 8px;
    }
    input {
      width: calc(100% / 6);
      aspect-ratio: 2 / 2;
      border: 2px solid #d0d5dd;
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      font-size: 24px;
      color: $white;
      font-family: "geist-medium";
      font-weight: 500;
      box-shadow: 0px 0px 12px rgba(#d0d5dd, 10%);
      transition: 0.3s linear;
      @media (max-width: 768px) {
        font-size: 20px;
      }
      &.errorInput {
        border: 2px solid #f97066;
        &:hover {
          border: 2px solid #f97066;
        }
      }
      &:hover {
        border: 2px solid $primary;
      }
      &:focus {
        border: 2px solid $primary;
      }
    }
  }
}

.error {
  color: #f97066;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-family: "geist-regular";
  font-weight: 400;
}

.formBtns {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}
