/* Basic CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
}
html,
body {
  height: 100%;
  background-color: #010314;
}
img,
video {
  max-width: 100%;
  height: auto;
}

button,
input,
select,
textarea {
  font-family: inherit; /* Inherit fonts to ensure consistency */
  outline: none;
  background-color: transparent;
  border: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
