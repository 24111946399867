@import "../../../../styles/variable";

.authApp {
  background-color: #0c111d;
  border: 1px solid #2b2d39;
  padding: 24px;
  border-radius: 12px;
  .header {
    position: relative;
    h3 {
      color: #f5f5f6;
      font-size: 30px;
      line-height: 38px;
      font-family: "geist-semibold";
      font-weight: 600;
      position: relative;
      z-index: 1;
      margin-block: 24px 12px;
    }
    p {
      margin-bottom: 32px;
      position: relative;
      z-index: 1;
      color: #94969c;
      font-size: 16px;
      line-height: 24px;
      font-family: "geist-regular";
      font-weight: 400;
    }
  }
  h5 {
    font-size: 14px;
    line-height: 20px;
    font-family: "geist-medium";
    font-weight: 500;
    position: relative;
    color: #cecfd2;
    text-align: left;
  }
  h6 {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-family: "geist-regular";
    font-weight: 400;
    color: #94969c;
    position: relative;
    margin-top: 10px;
    span {
      cursor: pointer;
      border-bottom: 1px solid #94969c;
    }
  }
  .verificationCode {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    span {
      color: #333741;
      font-size: 60px;
      line-height: 72px;
      font-family: "geist-medium";
      font-weight: 500;
      &.errorInput {
        color: #f97066;
      }
    }
    .otpCode {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      margin-block: 5px;
      @media (max-width: 768px) {
        gap: 8px;
      }
      input {
        width: calc(100% / 6);
        aspect-ratio: 2 / 2;
        border: 2px solid #d0d5dd;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        font-size: 24px;
        color: $white;
        font-family: "geist-medium";
        font-weight: 500;
        box-shadow: 0px 0px 12px rgba(#d0d5dd, 10%);
        transition: 0.3s linear;
        @media (max-width: 768px) {
          font-size: 20px;
        }
        &.errorInput {
          border: 2px solid #f97066;
          &:hover {
            border: 2px solid #f97066;
          }
        }
        &:hover {
          border: 2px solid $primary;
        }
        &:focus {
          border: 2px solid $primary;
        }
      }
    }
  }

  .error {
    color: #f97066;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-family: "geist-regular";
    font-weight: 400;
  }

  .formBtns {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
  .qrCode {
    background-color: $white;
    border-radius: 8px;
    padding: 12px;
    width: 240px;
    height: 240px;
    margin-inline: auto;
    margin-bottom: 20px;
  }
  .message {
    margin-bottom: 15px;
    text-align: left;
    label {
      color: #cecfd2;
      font-size: 14px;
      line-height: 20px;
      font-family: "geist-medium";
      font-weight: 500;
    }
    .smsInput {
      background-color: #0c111d;
      border: 1px solid #333741;
      border-radius: 8px;
      padding-left: 12px;
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 5px;
      min-height: 44px;
      justify-content: space-between;
      @media (max-width: 600px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 0px;
        padding-left: 0px;
      }
      input {
        max-width: 160px;
        font-size: 16px;
        line-height: 24px;
        font-family: "geist-medium";
        font-weight: 500;
        color: #f5f5f6;
        @media (max-width: 600px) {
          max-width: none;
          width: 100%;
          padding-inline: 12px;
          padding-block: 10px;
        }
      }
      button {
        font-size: 16px;
        line-height: 24px;
        font-family: "geist-semibold";
        font-weight: 600;
        color: #cecfd2;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #161b26;
        border-right: 1px solid #333741;
        padding-inline: 15px;
        cursor: pointer;
        min-width: 200px;
        min-height: 44px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          min-width: auto;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
