@import "../../styles/variable";

.career-section {
  margin-top: 70px;
  padding-inline: 20px;
  .heading {
    max-width: 768px;
    width: 100%;
    margin-inline: auto;
    text-align: center;
    h2 {
      font-size: 47px;
      line-height: 54px;
      font-family: "geist-medium";
      font-weight: 500;
      letter-spacing: -0.48px;
      margin-bottom: 20px;
      background: radial-gradient(circle, rgba($white, 1), rgba($white, 50%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      @media (max-width: 768px) {
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 10px;
      }
    }
    p {
      max-width: 700px;
      width: 100%;
      margin-inline: auto;
      color: #94969c;
      font-size: 20px;
      font-family: "geist-regular";
      line-height: 30px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
