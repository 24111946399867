.skeleton-loader {
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 400px;
    @media (max-width: 768px) {
      gap: 20px;
    }
    .mbl-width {
      width: 300px;
      height: 30px;
      opacity: 0.2;
      &.first {
        @media (max-width: 768px) {
          width: 250px;
        }
      }
      &.second {
        width: 768px;
        @media (max-width: 768px) {
          width: 320px;
        }
      }
      &.third {
        width: 600px;
        @media (max-width: 768px) {
          width: 280px;
        }
      }
      &.fourth {
        width: 500px;
        @media (max-width: 768px) {
          width: 200px;
        }
      }
    }
  }
  