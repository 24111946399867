@import "../../styles/variable";

header {
  border-bottom: 1px solid rgba(0, 0, 0, 7%);
  padding-top: 15px;
  width: 100%;
  top: 0;
  position: fixed;
  background-color: transparent;
  z-index: 99;
  transition: 0.3s ease;
  backdrop-filter: blur(10px);
  &.menu-open {
    background-color: rgba($dark, 90%);
    backdrop-filter: blur(5px);
  }
  .nav-header {
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    &.container {
      overflow: visible;
    }
    .logo {
      a {
        svg {
          .white-bg {
            transition: 0.3s linear;
          }

          .hover {
            transition: 0.3s linear;
          }
        }
      }
      &:hover {
        a {
          svg {
            .white-bg {
              fill: $white;
            }
            .hover {
              fill: $primary;
            }
          }
        }
      }
    }
    .nav-items {
      display: block;
      ul {
        border-radius: 30px;
        background-color: rgba($dark, 75%);
        padding: 14px 20px;
        border: 1px solid rgba($white, 20%);
        list-style: none;
        display: flex;
        align-items: center;
        gap: 32px;
        li {
          cursor: pointer;
          &:hover {
            a,
            p {
              color: $primary;
            }
          }
          a,
          p {
            transition: 0.3s ease;
            color: $white;
            text-decoration: none;
            font-size: 14px;
            font-family: "geist-medium";
            font-weight: 500;
          }
        }
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
    .nav-btns {
      display: flex;
      align-items: center;
      gap: 12px;
      @media (max-width: 992px) {
        display: none;
      }
      a {
        text-decoration: none;
        font-size: 14px;
        font-family: "geist-semibold";
        font-weight: 600;
        border-radius: 4px;
        padding: 10px 15px;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        z-index: 1;
        padding-left: 0;
        &.login {
          color: $white;
          &:hover {
          }
        }
        &.signup {
          background-color: $primary;
          color: $white;
          border: 1px solid $primary;
          &::after,
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 100%;
            background: $white;
            transition: 0.6s ease-out;
            z-index: -1;
            top: 0;
          }
          &::before {
            content: "";
            left: 0px;
          }
          &::after {
            content: "";
            right: 0px;
          }
          &:hover {
            color: $primary;
            &::before {
              width: 52%;
            }
            &::after {
              width: 52%;
            }
          }
        }
      }
    }
    .dropdownWrapper {
      position: relative;
      display: inline-block;
      .login {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .dropdownMenu {
        opacity: 0;
        position: absolute;
        top: 100%;
        height: 0px;
        transition: 0.3s ease-in-out;
        right: 0;
        background-color: $dark;
        box-shadow: 0 0px 16px rgba(255, 255, 255, 0.02);
        z-index: 10;
        display: flex;
        flex-direction: column;
        min-width: 130px;
        padding-block: 0px;
        border-radius: 10px;
        &.open {
          top: 100%;
          opacity: 1;
          height: 117px;
          opacity: 1;
          padding-block: 10px;
          .dropdownItem {
            opacity: 1;
            padding-block: 7px !important;
          }
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: -10px;
            right: 10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 11px solid $dark;
          }
        }
        .dropdownItem {
          opacity: 0;
          text-decoration: none;
          cursor: pointer;
          padding-inline: 10px !important;
          padding-block: 0px !important;
          color: $white;
          &:hover {
            background-color: $primary;
          }
        }
      }
    }
    .nav-item-mobile {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 5px;
      @media (min-width: 992px) {
        display: none;
      }
      cursor: pointer;
      &.animate {
        span {
          &:nth-child(2) {
            opacity: 0;
          }
          &:first-child {
            width: 20px;
            transform: rotate(-45deg);
            transform-origin: 15px 0px;
          }
          &:last-child {
            transform: rotate(45deg);
            transform-origin: 27px -1px;
            width: 20px;
          }
        }
      }
      span {
        height: 3px;
        border-radius: 10px;
        background-color: $white;
        width: 30px;
        transition: 0.3s ease-out;
        &:last-child {
          width: 10px;
        }
        &:nth-child(2) {
          width: 20px;
        }
      }
    }
  }
}
.mbl-menu {
  overflow: hidden;
  position: fixed;
  width: 350px;
  min-height: 100vh;
  left: -400px;
  top: 80px;
  transition: 0.3s linear;
  // background-color: $dark;
  background-color: rgba($dark, 90%);
  backdrop-filter: blur(5px);
  z-index: 999;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 3%);
  padding: 30px;
  &.toggle-menu {
    left: 0;
  }
  @media (max-width: 380px) {
    width: 100%;
  }
  .mbl-nav-items {
    .header {
      display: flex;
      align-items: center;
      gap: 30px;
      justify-content: space-between;
      .header-no-animate {
        transition: 0.2s ease-in;
        transition-delay: 0.3s;
        opacity: 0;
        margin-left: -100px;
      }
      .header-animate {
        opacity: 1;
        margin-left: 0px;
      }
      .close-menu {
        cursor: pointer;
        &.close-no-animate {
          transition: 0.2s ease-in;
          transition-delay: 0.6s;
          opacity: 0;
          margin-right: -100px;
        }
        &.close-animate {
          opacity: 1;
          margin-right: 0px;
        }
      }
    }
    ul {
      list-style: none;
      margin-block: 40px;
      li {
        overflow: hidden;
        padding-block: 10px;
        cursor: pointer;
        a,
        p {
          text-decoration: none;
          color: $white;
          font-family: "geist-medium";
          font-weight: 500;
        }
      }
    }
  }
  .mbl-nav-btns {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: column;
    .dropdownWrapper {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    .login {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
    }

    .dropdownMenu {
      width: 100%;
      background-color: rgba(#bea7ff, 5%);
      box-shadow: 0 0px 16px rgba(255, 255, 255, 0.02);
      z-index: 10;
      flex-direction: column;
      min-width: 130px;
      padding: 0px;
      border-radius: 10px;
      padding-inline: 0;
      display: flex;
      transition: 0.3s linear;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 0px;
      opacity: 0;
      &.open {
        opacity: 1;
        height: 100%;
        padding: 10px;
      }
    }

    .dropdownItem {
      text-decoration: none;
      cursor: pointer;
      padding-inline: 10px !important;
      padding-block: 7px !important;
      color: $white;
      text-align: center;
      &:hover {
        background-color: $primary;
      }
    }
    a,
    button {
      width: 100%;
      font-size: 14px;
      font-family: "geist-semibold";
      font-weight: 600;
      border-radius: 4px;
      padding: 10px 15px;
      white-space: nowrap;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: $white;
    }
    button {
      &::before {
        font-size: 14px;
      }
      &::after {
        bottom: -200%;
      }
      &:hover {
        &::after {
          bottom: 200%;
        }
      }
    }
  }
}
