@import "../../styles/variable";

.terms-agreement {
  max-width: 768px;
  margin-inline: auto;
  text-align: center;
  color: $white;
  padding-block: 96px;
  margin-top: 66px;
  .content {
    h2 {
      padding-block: 12px 24px;
      font-size: 47px;
      line-height: 54px;
      font-family: "geist-medium";
      font-weight: 500;
      background: radial-gradient(circle, rgba($white, 1), rgba($white, 50%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .desc {
    margin-top: 75px;
    text-align: left;
    .link {
      font-size: 16px;
      font-family: "geist-medium";
      font-weight: 500;
      color: $primary;
    }
    h5 {
      margin-block: 20px 10px;
      font-size: 20px;
      font-family: "geist-medium";
      font-weight: 500;
      color: $white;
    }
    h4,
    h3 {
      margin-block: 15px 10px;
      font-size: 16px;
      font-family: "geist-medium";
      font-weight: 500;
      color: $white;
    }
    ul li,
    p {
      color: #94969c;
      font-size: 16px;
      line-height: 24px;
      font-family: "geist-regular";
      font-weight: 400;
      b {
        color: rgba($white, 85%);
        font-weight: 400;
      }
      a {
        color: $primary;
      }
    }
    .dot-list {
      li {
        list-style-type: disc;
        margin-left: 15px;
      }
    }
  }
}
