@import "../../styles/variable";

.contact-section {
  margin-inline: 32px;
  margin-bottom: 100px;
  padding-bottom: 0 !important;
  border-radius: 34px;
  position: relative;
  @media (max-width: 768px) {
    border-radius: 16px;
    margin-bottom: 50px;
    margin-inline: 0px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 101%;
    height: 100%;
    background: rgb(1, 3, 20);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(1, 3, 20);
    background: -moz-linear-gradient(
      180deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#010314",endColorstr="#010314",GradientType=1);
  }
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    width: 101%;
    max-height: 312px;
    height: 100%;
    background: rgb(1, 3, 20);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    background: rgb(1, 3, 20);
    background: -moz-linear-gradient(
      180deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#010314",endColorstr="#010314",GradientType=1);
  }
  .contact-video-bg {
    width: 100%;
    height: 100%;
    border-radius: 38px;
    video {
      border-radius: inherit;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
      min-height: 840px;
      @media (max-width: 1024px) {
        object-position: center;
      }
      @media (max-width: 992px) {
        min-height: 900px;
        object-position: center;
      }
    }
  }
  &.contact-page {
    video {
      @media (max-width: 992px) {
        height: 100%;
        min-height: 700px;
      }
    }
  }
  .container {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .heading {
    text-align: center;
    width: 100%;
    margin-bottom: 70px;
    @media (max-width: 1024px) {
      margin-bottom: 35px;
    }
    h3 {
      font-size: 48px;
      line-height: 54px;
      letter-spacing: -0.48px;
      margin-block: 12px;
      font-family: "geist-medium";
      font-weight: 500;
      background: radial-gradient(circle, rgba($white, 1), rgba($white, 50%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    p {
      max-width: 616px;
      width: 100%;
      font-size: 15px;
      line-height: 23px;
      color: #dfe1f4;
      margin-inline: auto;
      text-align: center;
      font-family: "geist-regular";
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
  .cards {
    max-width: 1052px;
    width: 100%;
    margin-inline: auto;
    margin-top: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    @media (max-width: 1024px) {
      gap: 16px;
      margin-top: 48px;
    }
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
}

.modal-class {
  max-width: 580px !important;
  background: $dark !important;
  border-radius: 12px;
  padding: 32px 20px !important;
  .react-responsive-modal-closeButton {
    svg {
      fill: $white;
    }
  }
  .header {
    h3 {
      font-size: 28px;
      font-family: "geist-semibold";
      font-weight: 600;
      line-height: 40px;
      color: $white;
    }
    p {
      font-size: 16px;
      font-family: "geist-regular";
      font-weight: 400;
      line-height: 26px;
      color: rgba($white, 0.8);
      // margin-top: 12px;
    }
  }
  form {
    margin-top: 24px;
    .input {
      width: 100%;
      border-radius: 8px;
      background-color: #010314;
      border: 1px solid rgba($black, 6%);
      &:not(:last-child) {
        margin-bottom: 10px;
        @media (max-width: 1024px) {
          margin-bottom: 12px;
        }
      }
      input,
      textarea,
      select {
        height: 42px;
        padding-left: 17px;
        border-radius: inherit;
        width: 100%;
        font-size: 16px;
        color: $white;
        font-family: "geist-regular";
        font-weight: 400;
        &::placeholder {
          color: rgba($white, 55%);
        }
        @media (max-width: 1024px) {
          height: 44px;
        }
        @media (max-width: 768px) {
          height: 38px;
          padding-left: 10px;
          font-size: 14px;
        }
      }
      select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url("../../assets/images/downArrow.svg");
        background-position: right 10px center;
        background-repeat: no-repeat;
        option {
          background-color: #010314; /* Background of each option */
          color: $white;
        }
      }
      textarea {
        padding-top: 10px;
        resize: none;
        min-height: 190px;
        @media (max-width: 768px) {
          min-height: 130px;
        }
      }
    }
    button {
      width: 100%;
      margin-top: 20px;
    }
    p.error {
      color: red;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}
