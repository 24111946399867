@import "../../styles/variable";
footer {
  padding-bottom: 30px;
  max-width: 1200px;
  width: 100%;
  margin-inline: auto;
  @media (max-width: 992px) {
    padding-bottom: 24px;
    padding-top: 0;
    flex-direction: column;
    gap: 15px;
  }
  .footer-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 32px;
    @media (max-width: 992px) {
      grid-template-columns: 2fr 1fr 1fr;
      gap: 16px;
    }
    @media (max-width: 768px) {
      grid-template-columns: 2fr 1fr;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
    .col {
      h3 {
        color: #f5f5f6;
        font-family: "geist-semibold";
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .footer-text {
        color: $white;
        font-family: "geist-regular";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #94969c;
        margin-top: 25px;
        @media (max-width: 768px) {
          margin-top: 8px;
        }
      }
      ul {
        li {
          margin-bottom: 10px;
          p {
            cursor: pointer;
            color: #cecfd2;
            font-size: 16px;
            font-family: "geist-semibold";
            font-weight: 600;
            line-height: 24px;
            margin: 0;
          }
        }
      }
      .linkText {
        cursor: pointer;
        color: #cecfd2;
        font-size: 16px;
        font-family: "geist-regular";
        font-weight: 400;
        line-height: 24px;

        svg {
          rect {
            &.white-bg {
              transition: 0.5s ease-in-out;
            }
          }
          path {
            &.hover {
              transition: 0.5s ease-in-out;
            }
          }
        }
        &:hover {
          color: $white;
          svg {
            rect {
              &.white-bg {
                fill: $white;
              }
            }
            path {
              &.hover {
                fill: $primary;
              }
            }
          }
        }
      }
      form {
        display: flex;
        align-items: center;
        gap: 10px;
        @media (max-width: 425px) {
          flex-direction: column;
        }
        input {
          border: 1px solid #333741;
          border-radius: 8px;
          padding-inline: 12px;
          color: #85888e;
          font-size: 16px;
          line-height: 24px;
          font-family: "geist-regular";
          font-weight: 400;
          min-height: 41px;
          transition: 0.3s linear;
          @media (max-width: 425px) {
            width: 100%;
          }
          &:hover,
          &:focus {
            border: 1px solid $primary;
          }
        }
      }
    }
  }
  .bottom-grid {
    padding-top: 30px;
    margin-top: 64px;
    border-top: 1px solid #1f242f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    @media (max-width: 992px) {
      margin-top: 32px;
      padding-top: 24px;
    }
    @media (max-width: 768px) {
      gap: 12px;
      flex-direction: column-reverse;
      justify-content: center;
    }
    p {
      color: #94969c;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-family: "geist-regular";
      @media (max-width: 768px) {
        text-align: center;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .footer-social-icons {
      list-style-type: none;
      display: flex;
      align-items: center;
      gap: 24px;
      transition: 0.3s linear;
      li {
        svg {
          path {
            transition: 0.3s ease;
          }
        }
        &:hover {
          animation: shake 0.4s ease;
          .linkedin-icon {
            path {
              fill: #0072b1;
            }
          }
          .facebook-icon {
            path {
              fill: #039be5;
            }
          }

          .twitter-icon {
            path {
              fill: $white;
            }
          }
        }
      }
      @media (max-width: 768px) {
        gap: 20px;
      }
    }
  }
}

// social media icons animation
@keyframes shake {
  0% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(0.7) rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  75% {
    transform: scale(1.3) rotate(20deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
