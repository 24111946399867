@import "../../../../styles/variable";

.media-section {
  max-width: 1200px;
  width: 100%;
  margin-inline: auto;
  .header {
    padding-inline: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    @media (max-width: 768px) {
      padding-inline: 12px;
      gap: 15px;
      flex-direction: column;
    }
    .left {
      h2 {
        margin-top: 24px;
        font-size: 52px;
        line-height: 56px;
        font-family: "geist-medium";
        font-weight: 500;
        letter-spacing: -0.84px;
        color: $white;
        max-width: 500px;
        width: 100%;
        @media (max-width: 768px) {
          margin-top: 12px;
          font-size: 40px;
          line-height: 44px;
        }
        @media (max-width: 600px) {
          font-size: 35px;
          line-height: 40px;
        }
      }
      @media (max-width: 768px) {
        text-align: center;
        .tag {
          margin-inline: auto !important;
        }
      }
    }
  }
  .blog {
    display: flex;
    gap: 62px;
    justify-content: space-between;
    background-color: #060819;
    border-radius: 30px;
    padding: 24px 30px;
    margin-top: 62px;
    @media (max-width: 768px) {
      padding: 12px;
      margin-top: 32px;
      gap: 32px;
      flex-direction: column;
    }
    .blog-tag {
      span {
        font-size: 14.8px;
        line-height: 24px;
        font-family: "geist-medium";
        font-weight: 500;
        color: $white;
        letter-spacing: 0.2px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: -10px;
          width: 1px;
          height: 15px;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: $primary;
        }
      }
    }
    .blog-text {
      max-width: 437px;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      h4 {
        font-size: 36.22px;
        line-height: 48px;
        font-family: "geist-medium";
        font-weight: 500;
        color: $white;
        margin-block: 16px;
        letter-spacing: -0.2px;
        @media (max-width: 768px) {
          margin-block: 10px;
          font-size: 28.22px;
          line-height: 36px;
        }
        @media (max-width: 600px) {
          font-size: 22px;
          line-height: 28px;
        }
      }
      p {
        font-size: 17.86px;
        line-height: 28px;
        font-family: "geist-regular";
        font-weight: 400;
        color: rgba($white, 80%);
        margin-bottom: 16px;
        letter-spacing: 0.2px;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 22px;
        }
        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .blog-info {
      max-width: 540px;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      .blog-user {
        margin-top: 16px;
        margin-bottom: 85px;
        display: flex;
        gap: 16px;
        align-items: center;
        @media (max-width: 768px) {
          margin-bottom: 35px;
        }
        .img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid $white;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: inherit;
          }
        }
        .user {
          h5 {
            font-size: 14.18px;
            line-height: 24px;
            font-family: "geist-medium";
            font-weight: 500;
            color: $white;
            letter-spacing: 0.2px;
          }
          p {
            font-size: 15px;
            line-height: 24px;
            font-family: "geist-regular";
            font-weight: 400;
            color: rgba($white, 80%);
            letter-spacing: 0.2px;
          }
        }
      }
      .blog-img {
        border-radius: 8px;
        img {
          border-radius: inherit;
        }
      }
    }
  }
}
