@import "../../../../styles/variable";

.faqSection {
  .heading {
    text-align: center;
    width: 100%;
    margin-bottom: 70px;
    @media (max-width: 1024px) {
      margin-bottom: 35px;
    }
    h3 {
      font-size: 48px;
      line-height: 54px;
      color: $white;
      letter-spacing: -0.48px;
      margin-block: 12px;
      font-family: "geist-medium";
      font-weight: 500;
      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    p {
      max-width: 616px;
      width: 100%;
      font-size: 15px;
      line-height: 23px;
      color: #dfe1f4;
      margin-inline: auto;
      text-align: center;
      font-family: "geist-regular";
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
}
:global(.szh-accordion) {
  border: 0;
  max-width: 950px;
  margin-inline: auto;
  width: 100%;
}
:global(.szh-accordion__item) {
  padding-block: 20px;
  @media (max-width: 768px) {
    padding: 15px 0px;
  }
}
:global(.szh-accordion__item-heading) {
  button {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: #f5f5f6 !important;
    background: transparent;
    padding: 0;
    width: 100%;
    cursor: pointer;
    font-family: "geist-semibold";
    font-weight: 600;
    position: relative;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
    &::after {
      transition: 0.3s ease;
      content: url("../../../../assets/images/faq/add.svg");
      margin-left: auto;
    }
    &[aria-expanded="true"],
    &[aria-selected="true"] {
      &::after {
        content: url("../../../../assets/images/faq/minus.svg");
      }
    }
  }
}
:global(.szh-accordion__item-content) {
  padding: 0;
  width: 100%;
}
:global(.szh-accordion__item-panel) {
  padding-top: 10px;
  font-size: 18px;
  line-height: 28px;
  color: rgba($white, 70%);
  font-family: "geist-regular";
  font-weight: 400;
  max-width: 748px;
  letter-spacing: 0.2px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
    padding-right: 40px;
  }
}
:global(.szh-accordion__item-content) {
  transition: height 0.3s ease;
}

.list {
  list-style-type: disc;
  margin-left: 15px;
}
