@import "../../styles/variable";

.primary-button {
  background-color: $primary;
  min-width: 150px;
  min-height: 41px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  transition: 0.3s linear;
  justify-content: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 0px rgba(20, 110, 245, 0);
  color: #fff;
  font-size: 16px;
  padding-inline: 20px;
  z-index: 1;
  font-family: "geist-medium";
  font-weight: 500;
  cursor: pointer;
  &.less-radius {
    border-radius: 8px;
    min-width: 115px;
    // min-height: 44px;
  }
  &.dark {
    background-color: rgba(#171825, 75%);
    border: 1px solid rgba(#ffffff, 11%);
    &:hover {
      box-shadow: 0px 0px 12px rgba($white, 0.2);
    }
    &:disabled {
      cursor: not-allowed;
      background-color: #1f242f;
      border: 1px solid rgba($white, 11%);
      color: #85888e;
      &:hover {
        box-shadow: none;
        span {
          transform: none;
        }
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  &.secondary {
    background-color: $white;
    color: #010314;
    &::before {
      color: #010314;
    }
    &::after {
      background-color: rgba($dark, 0.1);
    }
    &:hover {
      box-shadow: 0px 0px 12px rgba($white, 0.4);
    }
  }
  @media (max-width: 768px) {
    font-size: 14px;
    min-height: 42px;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
  svg {
    transition: 0.3s linear;
  }
  span {
    transition: transform 0.3s ease-in-out;
    // img {
    //   width: 18px;
    //   height: 18px;
    //   object-fit: cover;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
  }
  &::before {
    content: attr(data-text);
    position: absolute;
    left: 0;
    right: 0;
    top: 199px;
    text-align: center;
    color: $white;
    font-size: 16px;
    transition: transform 0.3s ease-in-out;
    font-family: "geist-medium";
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.1);
    transition: 0.3s linear;
    transition-delay: 0.3s;
    z-index: -1;
    bottom: -200%;
    transform: skewY(10deg);
  }
  &:hover {
    box-shadow: 0px 0px 12px rgba($white, 0.4);
    span {
      transform: translateY(-150%);
    }
    svg {
      transform: translateX(5px);
    }
    &::before {
      transform: translateY(-188px);
    }
    &::after {
      bottom: 200%;
    }
  }
  .spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
  }

  .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255, 255, 255, 0.3); /* Light border */
    border-top: 3px solid white; /* White color for spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Continuous spinning */
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
