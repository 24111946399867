@import "../../../../styles/variable";

.hero-section {
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  border-radius: 38px;
  margin-inline: 32px;
  background-image: url("../../../../assets/images/hero/heroGradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  position: relative;
  display: flex;
  align-items: center;
  .container{
    position: relative;
    z-index: 1;
  }
  @media (max-width: 992px) {
    margin-inline: 15px;
  }
  @media (max-width: 768px) {
    padding-top: 150px;
    min-height: auto;
    padding-bottom: 60px;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
    border-bottom-left-radius: 0;
    max-width: 478px;
    border-radius: inherit;
    background: rgb(1, 3, 20);
    background: -moz-linear-gradient(
      270deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    background: -webkit-linear-gradient(
      270deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    background: linear-gradient(
      270deg,
      rgba(1, 3, 20, 1) 0%,
      rgba(1, 3, 20, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#010314",endColorstr="#010314",GradientType=1);
  }
  .hero-img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    // max-width: 865px;
    width: 100%;
    height: 100%;
    // mix-blend-mode: lighten;
    border-radius: 38px;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: inherit;
    }
  }
  .hero-content {
    max-width: 560px;
    width: 100%;
    @media (max-width: 768px) {
      padding-bottom: 40px;
    }
    h1 {
      margin-block: 24px;
      max-width: 480px;
      width: 100%;
      font-size: 62px;
      font-family: "geist-medium";
      font-weight: 500;
      line-height: 56px;
      letter-spacing: -0.84px;
      color: $white;
      @media (max-width: 992px) {
        margin-block: 18px;
        font-size: 55px;
        line-height: 50px;
      }
      @media (max-width: 768px) {
        margin-block: 12px;
        font-size: 40px;
        line-height: 36px;
      }
    }
    p {
      max-width: 309px;
      width: 100%;
      font-size: 15.2px;
      font-family: "geist-regular";
      font-weight: 400;
      line-height: 23.4px;
      color: #dfe1f4;
      margin-bottom: 24px;
    }
  }
}
