@import "../../../../styles/variable";

.case-study {
  max-width: 1200px;
  width: 100%;
  margin-inline: auto;
  .header {
    text-align: center;
    max-width: 680px;
    width: 100%;
    margin-inline: auto;
    h2 {
      font-size: 52px;
      line-height: 58px;
      font-family: "geist-medium";
      font-weight: 500;
      background: radial-gradient(circle, rgba($white, 1), rgba($white, 50%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-block: 24px;
      @media (max-width: 768px) {
        margin-block: 12px;
        font-size: 40px;
        line-height: 44px;
      }
      @media (max-width: 600px) {
        font-size: 35px;
        line-height: 40px;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      font-family: "geist-regular";
      font-weight: 400;
      color: #dfe1f4;
    }
  }
  .content {
    text-align: center;
    margin-top: 64px;
    padding-block: 100px 64px;
    border-radius: 24px;
    width: 100%;
    padding-inline: 20px;
    border: 1px solid rgba(213, 217, 235, 0.1);
    background: rgb(48, 39, 85);
    background: -moz-linear-gradient(
      175deg,
      rgba(48, 39, 85, 0.16) 0%,
      rgba(10, 6, 34, 0.04) 100%
    );
    background: -webkit-linear-gradient(
      175deg,
      rgba(48, 39, 85, 0.16) 0%,
      rgba(10, 6, 34, 0.04) 100%
    );
    background: linear-gradient(
      175deg,
      rgba(48, 39, 85, 0.16) 0%,
      rgba(10, 6, 34, 0.04) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#302755",endColorstr="#0a0622",GradientType=1);
    @media (max-width: 768px) {
      margin-top: 32px;
      padding-block: 50px 32px;
    }
    .content-logo {
      position: relative;
    }
    .content-video {
      max-width: 850px;
      width: 100%;
      margin-inline: auto;
      position: relative;
      z-index: 1;
      margin-top: 32px;
      @media (max-width: 768px) {
        margin-top: 15px;
      }
      &::before {
        content: "";
        position: absolute;
        width: 500px;
        height: 500px;
        border: 1px solid rgba(#bea7ff, 24%);
        top: 150px;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        @media (max-width: 768px) {
          width: 400px;
          height: 400px;
          top: 130px;
        }
        // @media (max-width: 600px) {
        //   width: 300px;
        //   height: 200px;
        //   top: 80px;
        // }
        @media (max-width: 500px) {
          width: 85%;
          height: 85%;
          top: 60px;
        }
      }
      video,
      iframe {
        min-height: 540px;
        width: 100%;
        border-radius: 24px;
        border: 1px solid rgba(213, 217, 235, 0.1);
        @media (max-width: 768px) {
          min-height: 400px;
        }
        @media (max-width: 600px) {
          min-height: 300px;
        }
        @media (max-width: 500px) {
          min-height: 250px;
        }
      }
    }
    p {
      font-size: 20px;
      font-family: "geist-regular";
      font-weight: 400;
      line-height: 30px;
      max-width: 740px;
      width: 100%;
      margin-inline: auto;
      text-align: center;
      margin-top: 55px;
      color: $white;
      @media (max-width: 768px) {
        margin-top: 32px;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
