@import "../../../styles/variable";

.card {
  background-color: #010314;
  border-radius: 16px;
  padding: 32px;
  border: 1px solid rgba(#dfe1f4, 15%);
  max-width: 510px;
  margin-inline: auto;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 16px;
  }
  .icon {
    background-color: rgba(#bea7ff, 10%);
    padding: 15px;
    width: 50px;
    height: 50px;
    border-radius: 12px;
    border: 1px solid rgba(#dfe1f4, 10%);
    margin-bottom: 32px;
    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }
  h4 {
    color: #dfe1f4;
    font-size: 21px;
    line-height: 22px;
    font-family: "geist-medium";
    font-weight: 500;
    margin-bottom: 8px;
  }
  p {
    color: #babcd2;
    font-size: 15px;
    line-height: 25px;
    font-family: "geist-medium";
    font-weight: 500;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
  .link {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    &.card-input {
      background-color: rgba(#bea7ff, 10%);
      padding-left: 20px;
      border-radius: 30px;
      border: 1px solid rgba(#ffffe1, 10%);
      justify-content: space-between;
      input {
        width: calc(100% - 150px);
        border: 0;
        font-size: 14px;
        font-family: "geist-regular";
        font-weight: 400;
        color: rgba(#ffffe1, 50%);
      }
    }
    a {
      background-color: rgba(#bea7ff, 10%);
      text-decoration: none;
      color: $white;
      font-size: 14px;
      line-height: 22px;
      font-family: "geist-medium";
      font-weight: 500;
      padding: 9px 20px;
      border-radius: 99px;
      white-space: nowrap;
      min-width: 140px;
      span {
        transition: 0.3s linear;
        margin-left: 5px;
      }
      &:hover {
        span {
          margin-left: 10px;
        }
      }
    }
  }
}
