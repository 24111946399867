@import "../../../../styles/variable";

.product-section {
  margin-inline: 32px;
  background-image: url("../../../../assets/images/hero/heroGradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  border-radius: 32px;
  @media (max-width: 992px) {
    border-radius: 20px;
    margin-inline: 0px;
  }
  .product-content {
    max-width: 1160px;
    margin-inline: auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
    .text-content {
      max-width: 695px;
      width: 100%;
      h2 {
        margin-top: 12px;
        font-size: 52px;
        font-weight: 500;
        font-family: "geist-medium";
        line-height: 56px;
        letter-spacing: -0.84px;
        color: $white;
        @media (max-width: 768px) {
          font-size: 34px;
          line-height: 38px;
        }
      }
      .text {
        max-width: 568px;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        font-family: "geist-regular";
        line-height: 28px;
        color: $white;
        margin-block: 20px;
        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 25px;
        }
      }
      .bottom-content {
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding-top: 64px;
        @media (max-width: 600px) {
          gap: 24px;
          padding-top: 32px;
          flex-direction: column;
        }
        ul {
          display: flex;
          gap: 32px;
          @media (max-width: 600px) {
            gap: 16px;
            flex-direction: column;
          }
          li {
            max-width: 347px;
            @media (max-width: 600px) {
              max-width: 100%;
            }
            h4 {
              font-size: 15px;
              line-height: 20px;
              font-family: "geist-medium";
              font-weight: 500;
              color: $white;
              margin-bottom: 8px;
            }
            p {
              font-size: 13.6px;
              line-height: 24px;
              font-family: "geist-regular";
              font-weight: 400;
              color: #babcd2;
            }
          }
        }
      }
    }
    .right-content {
      max-width: 473px;
      width: 100%;
      background-color: #030014;
      border: 1px solid rgba($white, 25%);
      border-radius: 24px;
      @media (max-width: 1200px) {
        max-width: 400px;
      }
      .upper-card {
        margin: 12px;
        background-color: $white;
        border-radius: 12px;
        border: 1px solid rgba(#d0d5dd, 50%);
        padding: 24px;
        min-height: 216px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .heading {
            display: flex;
            gap: 3px;
            align-items: center;
            .coin-img {
              width: 24px;
              height: 24px;
            }
            h4 {
              font-size: 16px;
              line-height: 20px;
              font-family: "geist-semibold";
              font-weight: 600;
              color: #323232;
            }
          }
          .info {
            background-color: #f6f6f6;
            border: 1px solid rgba($black, 1%);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
          }
        }
        .bottom {
          .tag {
            font-size: 12px;
            line-height: 18px;
            font-family: "geist-medium";
            font-weight: 500;
            color: #344054;
            border: 1px solid #eaecf0;
            border-radius: 20px;
            padding: 4px 10px;
            margin-bottom: 8px;
          }
          h5 {
            font-size: 18px;
            line-height: 20px;
            font-family: "geist-bold";
            font-weight: 700;
            color: #323232;
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              color: #475467;
              font-size: 12px;
              line-height: 24px;
              font-family: "geist-regular";
              font-weight: 400;
            }
          }
        }
      }
      .send-eth {
        margin: 12px;
        margin-top: 10px;
        padding: 10px;
        border-radius: 20px;
        background-color: rgba($white, 7%);
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        @media (max-width: 1200px) {
          padding: 5px 10px;
          border-radius: 20px;
        }
        .send-btn {
          cursor: pointer;
          background-color: $primary;
          display: flex;
          justify-content: space-between;
          border-radius: 20px;
          padding: 22px 18px;
          min-width: 257px;
          @media (max-width: 1200px) {
            padding: 15px;
            border-radius: 14px;
            min-width: 200px;
          }
          p {
            font-size: 18px;
            line-height: 20px;
            font-family: "geist-medium";
            font-size: 500;
            color: $white;
          }
          span {
            font-size: 18px;
            line-height: 20px;
            font-family: "geist-bold";
            font-size: 700;
            color: $white;
          }
        }
        .curr-icon {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border-radius: 20px;
          @media (max-width: 1200px) {
            border-radius: 14px;
            width: 50px;
            height: 50px;
          }
        }
        .icon {
          border-radius: 20px;
          background-color: $primary;
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 1200px) {
            border-radius: 14px;
            width: 50px;
            height: 50px;
          }
        }
      }
      .earth {
        border-radius: inherit;
        position: relative;
        .loader {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          gap: 5px;
          p {
            font-size: 18px;
            line-height: 20px;
            font-family: "geist-medium";
            font-weight: 500;
            color: rgba($white, 75%);
          }
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;
            span {
              height: 10px;
              border-radius: 10px;
              width: 3px;
              transform-origin: bottom;
              background-color: rgba($white, 75%);
              &.bar1 {
                animation: growHeight 1s ease-in-out infinite;
              }
              &.bar2 {
                animation: growHeight 1.5s ease-in-out infinite;
                animation-delay: 0.5s; /* Start after bar1 */
              }
              &.bar3 {
                animation: growHeight 1.5s ease-in-out infinite;
                animation-delay: 1s; /* Start after bar2 */
              }
            }
          }
        }
        img {
          border-radius: inherit;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

/* Keyframes for growing height */
@keyframes growHeight {
  0%,
  100% {
    height: 8px; /* Start from 0% height */
  }
  50% {
    height: 16px; /* End at 100% height */
  }
}
