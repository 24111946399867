@import "../../styles/variable";

.contact-page-section {
  margin-inline: 32px;
  text-align: center;
  min-height: 100vh;
  border-radius: 32px;
  margin-block: 100px;
  padding-bottom: 190px !important;
  background-image: url("../../assets/images/hero/heroGradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  @media (max-width: 768px) {
    margin-block: 75px;
    margin-inline: 0px;
    padding-inline: 20px;
  }
  .content {
    max-width: 480px;
    margin-inline: auto;
    width: 100%;

    span {
      font-size: 16px;
      line-height: 24px;
      font-family: "geist-semibold";
      font-weight: 600;
      color: #cecfd2;
    }

    h2 {
      font-size: 48px;
      line-height: 60px;
      font-family: "geist-semibold";
      font-weight: 600;
      letter-spacing: -2%;
      color: #f5f5f6;
      margin-block: 8px 16px;
      @media (max-width: 768px) {
        margin-block: 4px 12px;
        font-size: 36px;
        line-height: 48px;
      }
    }

    p {
      font-size: 20px;
      line-height: 30px;
      font-family: "geist-regular";
      font-weight: 400;
      color: #94969c;
      margin-bottom: 64px;
      @media (max-width: 768px) {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    form {
      .row {
        display: flex;
        // align-items: center;
        gap: 32px;
        justify-content: space-between;

        .input {
          margin-bottom: 15px;
          text-align: left;
          width: 100%;

          label {
            font-size: 14px;
            line-height: 20px;
            font-family: "geist-medium";
            font-weight: 500;
            color: #cecfd2;
          }

          input,
          textarea {
            margin-top: 4px;
            background-color: #0c111d;
            border: 1px solid #333741;
            border-radius: 8px;
            padding: 10px 14px;
            font-size: 16px;
            line-height: 24px;
            font-family: "geist-regular";
            font-weight: 400;
            color: rgba($white, 100%);
            width: 100%;
            transition: 0.3s linear;

            &::placeholder {
              color: #85888e;
            }
            &:hover, &:focus {
              border: 1px solid $primary;
            }
          }

          textarea {
            resize: none;
            min-height: 134px;
          }

          .error-input {
            border-color: #f00;

            &:hover {
              border-color: #f00;
            }
          }

          .error {
            color: #f97066;
            font-size: 14px;
            width: 100%;
            font-family: "geist-medium";
          }
        }
      }

      .form-check {
        display: flex;
        gap: 8px;

        label {
          color: #94969c;
          font-size: 16px;
          font-family: "geist-regular";
          font-weight: 400;
        }

        input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #333741;
          border-radius: 6px;
          outline: none;
          cursor: pointer;
        }

        /* Background color for unchecked state */
        input[type="checkbox"]:not(:checked) {
          background-color: transparent;
        }

        /* Background color for checked state */
        input[type="checkbox"]:checked {
          background-color: $primary;
          border-color: $primary;
        }

        /* Create a custom checkmark */
        input[type="checkbox"]:checked::after {
          content: "";
          display: block;
          width: 4px;
          height: 11px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          margin: 0px 6px;
        }
      }

      button {
        width: 100%;
        margin-top: 30px;

        &::after {
          bottom: -200%;
          transform: skewY(10deg);
        }

        &:hover {
          &::after {
            bottom: 200%;
          }
        }
      }
    }
  }
}
